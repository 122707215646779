import React from "react"
import { graphql } from "gatsby"

import PostTemplate from "src/templates/PostTemplate.js"

const Article = ({ data: { article, bottom_cta } }) => {
  return <PostTemplate bottom_cta={bottom_cta} post={article} type="learn" />
}

export default Article

export const query = graphql`
  query ArticleSlug($slug: String!) {
    article: contentfulArticle(slug: { eq: $slug }) {
      ...Article
    }
    bottom_cta: contentfulSection(sectionId: { eq: "bottom_newsletter_cta" }) {
      ...Section
    }
  }
`
